exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atencion-cliente-js": () => import("./../../../src/pages/atencion-cliente.js" /* webpackChunkName: "component---src-pages-atencion-cliente-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-canal-etico-js": () => import("./../../../src/pages/canal-etico.js" /* webpackChunkName: "component---src-pages-canal-etico-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-politica-conflicto-intereses-js": () => import("./../../../src/pages/politica-conflicto-intereses.js" /* webpackChunkName: "component---src-pages-politica-conflicto-intereses-js" */),
  "component---src-pages-politica-cookies-js": () => import("./../../../src/pages/politica-cookies.js" /* webpackChunkName: "component---src-pages-politica-cookies-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../../../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-presupuesto-js": () => import("./../../../src/pages/presupuesto.js" /* webpackChunkName: "component---src-pages-presupuesto-js" */),
  "component---src-pages-quienes-somos-js": () => import("./../../../src/pages/quienes-somos.js" /* webpackChunkName: "component---src-pages-quienes-somos-js" */),
  "component---src-pages-registro-usuarios-js": () => import("./../../../src/pages/registro-usuarios.js" /* webpackChunkName: "component---src-pages-registro-usuarios-js" */),
  "component---src-pages-solicitar-demo-js": () => import("./../../../src/pages/solicitar-demo.js" /* webpackChunkName: "component---src-pages-solicitar-demo-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tags-js": () => import("./../../../src/templates/blog-tags.js" /* webpackChunkName: "component---src-templates-blog-tags-js" */)
}

